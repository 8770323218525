import {
  GET_AFC_CASHBAG_E_LOGBOOK,
  GET_GTM_CASHBOX_E_LOGBOOK,
  GET_GTM_COINFLOAT_E_LOGBOOK,
  GET_MANUAL_CHANGE_SUMMARY,
  GET_PRE_ENCODED_SUMMARY,
  GET_PSC_CASHBAG_E_LOGBOOK,
  GET_PSM_SUMMARY,
  GET_SCCF_SUMMARY,
  GET_STATION_TRANSFER_SUMMARY,
  GET_SM_DUTY_RADIO_AND_DUTY_PHONE,
  GET_SM_DUTY_KEYS,
  GET_FITNESS_CONFIRMATION,
  GET_LAST_HANDOVER_INFO,
  GET_STATION_OCCURRENCE_SUMMARY,
  GET_STATION_OUTSTANDING_FAULT_SUMMARY,
  GET_SMARTCARD_INVENTORY_SUMMARY,
  CREATE_SHIFT_HANDOVER_REPORT,
  GET_SHIFT_HANDOVER_REPORTS_LIST,
  GET_SHIFT_HANDOVER_REPORT_DETAILS,
  GET_SM_DUTY_ITEM_LOGS,
  GET_SM_DUTY_ITEM_LIST,
  CREATE_SM_DUTY_ITEM,
  UPDATE_SM_DUTY_ITEM,
  GET_SM_KEY_LOGS,
  GET_SM_KEY_LIST,
  CREATE_SM_KEY,
  UPDATE_SM_KEY,
  CONFIRM_TAKEOVER,
  SHIFT_HANDOVER_HEALTH_CHECK,
  REMOVE_SM_DUTY_ITEM,
  REMOVE_SM_KEY,
  GET_PSC_CDF_SUMMARY,
} from "../constants/apis";
import createAxiosInstance from "./createAxiosInstance";
import { getURLSearchParams } from "./service.helpers";
import { successHandler, errorHandler } from "./serviceHandlers";

export async function shiftHandoverHealthCheck() {
  try {
    const response = await createAxiosInstance(false).get(
      SHIFT_HANDOVER_HEALTH_CHECK
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function confirmTakeover(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CONFIRM_TAKEOVER,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getSMKeyList({ lineId, stationId }) {
  try {
    const params = getURLSearchParams({
      data: {
        lineId,
        stationId,
      },
    });
    const response = await createAxiosInstance(true).get(GET_SM_KEY_LIST, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getSMKeyLogs({
  lineId,
  stationId,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        lineId,
        stationId,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(GET_SM_KEY_LOGS, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createSMKey(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_SM_KEY,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function updateSMKey(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      UPDATE_SM_KEY,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function removeSMKey(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      REMOVE_SM_KEY,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getSMDutyItemList({ lineId, stationId }) {
  try {
    const params = getURLSearchParams({
      data: {
        lineId,
        stationId,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_SM_DUTY_ITEM_LIST,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getSMDutyItemLogs({
  lineId,
  stationId,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        lineId,
        stationId,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_SM_DUTY_ITEM_LOGS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createSMDutyItem(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_SM_DUTY_ITEM,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function updateSMDutyItem(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      UPDATE_SM_DUTY_ITEM,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function removeSMDutyItem(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      REMOVE_SM_DUTY_ITEM,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createShiftHandoverReport(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_SHIFT_HANDOVER_REPORT,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getShiftHandoverReportList({
  stationId,
  lineId,
  skip = 0,
  take = 1000,
  SearchDateFrom,
  SearchDateTo,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        stationId,
        lineId,
        skip,
        take,
        SearchDateFrom,
        SearchDateTo,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_SHIFT_HANDOVER_REPORTS_LIST,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getShiftHandoverReportDetails({ id }) {
  try {
    const params = getURLSearchParams({
      data: {
        id,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_SHIFT_HANDOVER_REPORT_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getAFCCashBagELogbook({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(
      GET_AFC_CASHBAG_E_LOGBOOK,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getGTMCashBoxELogbook({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(
      GET_GTM_CASHBOX_E_LOGBOOK,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getGTMCoinFloatELogbook({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(
      GET_GTM_COINFLOAT_E_LOGBOOK,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getManualChangeSummary({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(
      GET_MANUAL_CHANGE_SUMMARY,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getPscCashDeclarationSummary({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(
      GET_PSC_CDF_SUMMARY,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getPreEncodedSummary({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(
      GET_PRE_ENCODED_SUMMARY,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getPSCCashBagELogbook({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(
      GET_PSC_CASHBAG_E_LOGBOOK,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getPSMSummary({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(GET_PSM_SUMMARY, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getSCCFSummary({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(GET_SCCF_SUMMARY, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getStationTransferSummary({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(
      GET_STATION_TRANSFER_SUMMARY,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getSMDutyRadioSetAndDutyPhone({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(
      GET_SM_DUTY_RADIO_AND_DUTY_PHONE,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getSMDutyKeys({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(GET_SM_DUTY_KEYS, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getFitnessConfirmation({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(
      GET_FITNESS_CONFIRMATION,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getLastHandover({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(
      GET_LAST_HANDOVER_INFO,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getStationOccurrenceSummary({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(
      GET_STATION_OCCURRENCE_SUMMARY,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getStationOutstandingFaultSummary({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(
      GET_STATION_OUTSTANDING_FAULT_SUMMARY,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getSmartCardInventorySummary({
  stationId,
  lineId,
  startDate,
  endDate
}) {
  try {
    const params = getURLSearchParams({
      data: { stationId, lineId, startDate, endDate }
    });
    const response = await createAxiosInstance(true).get(
      GET_SMARTCARD_INVENTORY_SUMMARY,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}
