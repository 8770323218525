import {
  TOURIST_CREATE_MAP,
  TOURIST_GET_LANDMARKS,
  TOURIST_MAP_DETAILS,
  TOURIST_MAP_SEARCH,
  TOURIST_HEALTH_CHECK,
} from "../constants/apis";
import createAxiosInstance from "./createAxiosInstance";
import { getURLSearchParams } from "./service.helpers";
import { successHandler, errorHandler } from "./serviceHandlers";

export async function touristHealthCheck() {
  try {
    const response = await createAxiosInstance(false).get(TOURIST_HEALTH_CHECK);
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getLandMarks(files) {
  try {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file, file.name);
    });
    const response = await createAxiosInstance(true).post(
      TOURIST_GET_LANDMARKS,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function searchMap(search = "", skip = 0, take = 100) {
  try {
    const params = getURLSearchParams({
      data: {
        search,
        skip,
        take,
      },
    });
    const response = await createAxiosInstance(true).get(TOURIST_MAP_SEARCH, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function getMapDetails(stationId) {
  try {
    const params = getURLSearchParams({
      data: {
        stationId,
      },
    });
    const response = await createAxiosInstance(true).get(TOURIST_MAP_DETAILS, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function createMap(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      TOURIST_CREATE_MAP,
      formData
    );
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}
