import {
  CREATE_INVENTORY_LOCATION,
  EDIT_INVENTORY_LOCATION,
  DELETE_INVENTORY_LOCATION,
  GET_INVENTORY_LOCATION_DETAILS,
  GET_INVENTORY_LOCATION_LIST,
  CREATE_INVENTORY_TYPE,
  EDIT_INVENTORY_TYPE,
  DELETE_INVENTORY_TYPE,
  GET_INVENTORY_TYPE_LIST,
  GET_INVENTORY_TYPE_DETAILS,
  CREATE_INVENTORY_ORDER,
  GET_INVENTORY_ORDER_LIST,
  GET_INVENTORY_ORDER_DETAILS,
  EDIT_INVENTORY_ORDER_DOD,
  GET_INVENTORY_LAST_ORDER_AMOUNT,
  APPROVE_ORDER,
  REJECT_ORDER,
  MOVE_IN_ITEMS,
  MOVE_OUT_ITEMS,
  GET_INVENTORY_MAIN_PAGES,
  USE_ITEMS,
  CREATE_FIRST_AID_BOX,
  DELETE_FIRST_AID_BOX,
  UPDATE_FIRST_AID_BOX,
  GET_FIRST_AID_BOX_DETAILS,
  GET_FIRST_AID_BOX_LIST,
  CREATE_FIRST_AID_FORM,
  OPEN_SMART_CARD_BOX,
  GET_CONFIGURATION_MAIN_PAGE_DATA,
  EDIT_DELIVERY_ELAPSE_DAYS,
  EDIT_EXPIRE_THRESHOLD,
  GET_INVENTORY_MAIN_PAGE,
  INVENTORY_HEALTH_CHECK,
  VERIFY_FIRST_AID_BOX,
  UPDATE_INVENTORY,
  GET_INVENTORY_TYPE_THRESHOLD_DETAILS,
  GET_INVENTORY_TYPE_THRESHOLD_LIST,
  CREATE_INVENTORY_TYPE_THRESHOLD,
  EDIT_INVENTORY_TYPE_THRESHOLD,
  DELETE_INVENTORY_TYPE_THRESHOLD,
  FIRST_AID_LOG_DETAILS,
} from "../constants/apis";
import createAxiosInstance from "./createAxiosInstance";
import { successHandler, errorHandler } from "./serviceHandlers";
import { getURLSearchParams } from "./service.helpers";

export async function inventoryHealthCheck() {
  try {
    const response = await createAxiosInstance(false).get(
      INVENTORY_HEALTH_CHECK
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createInventoryLocation(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_INVENTORY_LOCATION,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editInventoryLocation(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      EDIT_INVENTORY_LOCATION,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function deleteInventoryLocation(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      DELETE_INVENTORY_LOCATION,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getInventoryLocationDetails({ inventoryLocationId }) {
  try {
    const params = getURLSearchParams({
      data: {
        inventoryLocationId,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_INVENTORY_LOCATION_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getListOfInventoryLocations({
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_INVENTORY_LOCATION_LIST,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createFirstAidBox(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_FIRST_AID_BOX,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function updateFirstAidBox(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      UPDATE_FIRST_AID_BOX,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function deleteFirstAidBox(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      DELETE_FIRST_AID_BOX,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function verifyFirstAidBox(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      VERIFY_FIRST_AID_BOX,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getFirstAidBoxDetails({ firstAidBoxId }) {
  try {
    const params = getURLSearchParams({
      data: {
        firstAidBoxId,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_FIRST_AID_BOX_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getFirstAidLogDetails({ inventoryTransactionId }) {
  try {
    const params = getURLSearchParams({
      data: {
        inventoryTransactionId,
      },
    });
    const response = await createAxiosInstance(true).get(
      FIRST_AID_LOG_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getListOfFirstAidBoxes({
  skip = 0,
  take = 100,
  search,
  stationId,
  lineId,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        skip,
        take,
        search,
        stationId,
        lineId,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_FIRST_AID_BOX_LIST,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createInventoryType(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_INVENTORY_TYPE,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editInventoryType(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      EDIT_INVENTORY_TYPE,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function deleteInventoryType(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      DELETE_INVENTORY_TYPE,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getInventoryTypeDetails({ inventoryTypeId, stationId }) {
  try {
    const params = getURLSearchParams({
      data: {
        inventoryTypeId,
        stationId,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_INVENTORY_TYPE_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getInventoryLastOrderAmount({
  inventoryTypeId,
  stationId,
  lineId,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        inventoryTypeId,
        stationId,
        lineId,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_INVENTORY_LAST_ORDER_AMOUNT,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getListOfInventoryTypes({
  inventoryGroup = null,
  stationId,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        inventoryGroup,
        stationId,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_INVENTORY_TYPE_LIST,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createInventoryOrder(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_INVENTORY_ORDER,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editInventoryOrder(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      EDIT_INVENTORY_ORDER_DOD,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getInventoryOrderDetails({ inventoryOrderId }) {
  try {
    const params = getURLSearchParams({
      data: {
        inventoryOrderId,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_INVENTORY_ORDER_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getListOfInventoryOrders(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      GET_INVENTORY_ORDER_LIST,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function approveOrder(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      APPROVE_ORDER,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function rejectOrder(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      REJECT_ORDER,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function moveInItems(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      MOVE_IN_ITEMS,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function moveOutItems(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      MOVE_OUT_ITEMS,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function useInventoryItems(formData) {
  try {
    const response = await createAxiosInstance(true).post(USE_ITEMS, formData);
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getInventoryMainPages({
  stationId,
  lineId,
  inventoryGroup,
  inventoryTypeName,
  locationName,
  searchDateFrom,
  searchDateTo,
  firstAidBoxList,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        stationId,
        lineId,
        inventoryGroup,
        inventoryTypeName,
        locationName,
        searchDateFrom,
        searchDateTo,
        firstAidBoxList,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_INVENTORY_MAIN_PAGES,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createFirstAidForm(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_FIRST_AID_FORM,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function openSmartCardBox(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      OPEN_SMART_CARD_BOX,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getConfigurationMainPageData(params) {
  try {
    const response = await createAxiosInstance(true).get(
      GET_CONFIGURATION_MAIN_PAGE_DATA,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editDeliveryElapseDays(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      EDIT_DELIVERY_ELAPSE_DAYS,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editExpireThreshold(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      EDIT_EXPIRE_THRESHOLD,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getInventoryMainPageData({ stationId, lineId }) {
  try {
    const params = getURLSearchParams({
      data: {
        stationId,
        lineId,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_INVENTORY_MAIN_PAGE,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function updateInventory(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      UPDATE_INVENTORY,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createInventoryTypeThreshold(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      CREATE_INVENTORY_TYPE_THRESHOLD,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}
export async function editInventoryTypeThreshold(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      EDIT_INVENTORY_TYPE_THRESHOLD,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function deleteInventoryTypeThreshold(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      DELETE_INVENTORY_TYPE_THRESHOLD,
      formData
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getInventoryTypeThresholdDetails({
  inventoryTypeThresholdId,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        inventoryTypeThresholdId,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_INVENTORY_TYPE_THRESHOLD_DETAILS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getListOfInventoryTypeThreshold({
  stationId = null,
  lineId = null,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        stationId,
        lineId,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(
      GET_INVENTORY_TYPE_THRESHOLD_LIST,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}
