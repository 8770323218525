import get from "lodash.get";
import isEmpty from "lodash.isempty";
import router from "../router";
import store from "../store";
import { downloadFile } from "@/services/file.service";
import createAxiosInstance from './createAxiosInstance';
import { getURLSearchParams } from "./service.helpers";

const ForbiddenMessage = "Forbidden: you are not authorized to view this content";
const NetworkErrorMessage = "Network Error: failed to connect with server.";
const ServiceUnavailableMessage = "Network Error: Service is unavailable. Please contact your admin for support.";
const GatewayTimeoutMessage = "Network Error: Server is taking too long to reply. Please contact your admin for support.";
const InternalServerErrorMessage = "Internal Server Error";
const GeneralError = "Server Error";

function getAPIResponseMessage(response) {
  if (response && !isEmpty(response.data) && typeof(response.data) === 'string')
    return response.data;
  else if (typeof get(response, 'data.errors') == 'object')
    return get(response, 'data.errors');
  return get(response, 'data.message', GeneralError);
}

function getErrorResponse(message, status = 0) {
  return {
    success: false,
    status,
    payload: {
      message,
    },
  }
}

export async function getAsync({
  url = '',
  params = {},
  withCredential = true,
  options = {}
}) {
  var urlParams = getURLSearchParams({ data: params });
  try {
    const response = await createAxiosInstance(withCredential).get(url, {
      params: urlParams,
      ...options
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function postAsync({
  url = '',
  body = {},
  withCredential = true,
  options = {},
}) {
  try {
    const response = await createAxiosInstance(withCredential).post(url, body, {
      ...options
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export const errorHandler = (error) => {
  const isHttpError = error.response && error.response.status;
  if (isHttpError) {
    const responseStatus = get(error, 'response.status', 0);

    switch (responseStatus) {
      case 200:
      case 400: {
        return getErrorResponse(getAPIResponseMessage(error.response), responseStatus);
      }
      case 401: {
        const allowsAnonymous = get(router, 'currentRoute.value.meta.allowsAnonymous');
        const isLoginPage = get(router, 'currentRoute.value.name') === 'Login';
        if (!allowsAnonymous && !isLoginPage) {
          // 使用LocalStorage记录401错误
          // -----------监测token
          const tokenObj = window.localStorage.getItem("tokenHistory");
          const deviceId = window.localStorage.getItem("asomsDeviceId");
          const tokenData = tokenObj ? JSON.parse(tokenObj) : [];
          tokenData.push({ 
            txt: '遇到401退出 - 清空了token',
            enTxt: 'Encountered 401 - Cleared Token',
            msg: error,
            date: new Date().toLocaleString(), 
            url: window.location.href.split('#')[1],
            asomsDeviceId: deviceId || null,
            token: `${get(store, 'state.auth.token')}`,
          })
          window.localStorage.setItem("tokenHistory", JSON.stringify(tokenData) );
          // -----------监测token
              
          store.dispatch("handle401Error");
          store.dispatch("loadInitialData");
          store.dispatch("clearData");
          router.push({ name: "Login", params: { forceLogout: true } });
        }
        return getErrorResponse(error.response.data, responseStatus);
      }
      case 403: {
        return getErrorResponse(ForbiddenMessage, 403);
      }
      case 500: {
        return getErrorResponse(InternalServerErrorMessage, 500);
      }
      case 503: {
        return getErrorResponse(ServiceUnavailableMessage, 503);
      }
      case 504: {
        return getErrorResponse(GatewayTimeoutMessage, 504);
      }
      default: {
        return getErrorResponse(getAPIResponseMessage(error.response), responseStatus);
      }
    }
  } else {
    const isNetworkError = error && error.message && error.message.includes && error.message.includes('Network Error');
    if (isNetworkError && !store.getters['apiStatus/isAllApiOnline']) {
      return getErrorResponse(NetworkErrorMessage);
    }
    
    return getErrorResponse(error.message);
  }
};

export const successHandler = (response) => {
  return {
    success: true,
    payload: response.data,
  };
};

export const downloadHandler = async (fileId, fileName) => {
  const response = await downloadFile({
    fileId: fileId,
  });
  if (response) {
    var blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    var link = document.createElement("a");
    link.className = "invisible";
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    link.remove();

    return {
      success: true,
      message: "File downloaded",
    };
  } else {
    return {
      success: false,
      message: "Failed to download file",
    };
  }
};
